@import "../variables";

.hero {
    position: relative;
    // min-height: 100dvh;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0px;
    overflow: hidden;
    // border: 2px solid red;
    // background: linear-gradient(180deg, #202020 0%, #343a40 100%);

    .hero__circle {
        width: 80%;
        // position: relative;
        margin-left: 90px;
        margin-top: -10px;
        z-index: 2;

        circle {
            position: absolute;
        }
    }

    .hero__blackDoor {
        position: absolute;
        z-index: 3;
        top: 60px;
        right: 0px;

        .blackDoor {
            width: 130px;
        }
    }

    .hero__video {
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 300px;
        object-fit: cover;
        top: 75px;
        right: 0px;
        z-index: 4;
    }

    .banner__profile {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 5px;
        z-index: 5;

        &--first,
        &--second {
            width: 100%;
            position: absolute;
            text-transform: uppercase;
            transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        }

        &--first {
            left: 0px;
            bottom: 75px;
        }

        &--second {
            left: 0px;
            bottom: 0px;
        }

        .motto {
            font-family: "Oswald Medium";
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            color: $white;
            text-shadow: 0px 4px 20px rgba($milk, 0.5);
            margin: 0;
            opacity: 0;
            transform: translateX(-50px);
            transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        }

        .motto2 {
            font-family: "Oswald Medium";
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            color: $white;
            text-shadow: 0px 4px 20px rgba($milk, 0.5);
            margin: 0;
            opacity: 0;
            transform: translateX(-50px);
            transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        }

        .motto.show,
        .motto2.show {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .scroll__down {
        position: fixed;
        bottom: 10px;
        right: 20px;
        z-index: 1000;

        &--button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $white;
            color: $black;
            box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2);
            animation-duration: 1.5s;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $md-max) {
    .hero {
        padding: 0px 24px;

        .hero__blackDoor {
            .blackDoor {
                width: 250px;
            }
        }

        .hero__video {
            max-height: 400px;
            width: 85%;
            right: 30px;
        }

        .banner__profile {
            bottom: 150px;
            left: 80px;

            &--first {
                left: -70px;
                bottom: 150px;

                .motto {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            &--second {
                left: -20px;
                bottom: 110px;

                .motto2 {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }

        .scroll__down {
            &--button {
                font-size: 12px;
                width: 38px;
                height: 38px;
            }
        }
    }
}

@media (min-width: $lg-max) {
    .hero {
        .hero__circle {
            width: 750px;
            margin-left: 200px;
            margin-top: -25px;
        }

        .hero__blackDoor {
            top: 40px;

            .blackDoor {
                width: 350px;
            }
        }

        .hero__video {
            width: 90%;
            max-height: 550px;
        }

        .banner__profile {
            &--first {
                left: -90px;
                bottom: 180px;
            }

            &--second {
                left: -30px;
                bottom: 100px;
            }

            .motto,
            .motto2 {
                font-family: "Oswald Bold";
                font-size: 48px;
                line-height: 60px;
            }
        }

        .scroll__down {
            &--button {
                font-size: 8px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media (min-width: 1359.98px) {
    .hero {
        .hero__circle {
            width: 950px;
            margin-left: 300px;
            margin-top: -45px;
        }

        .hero__blackDoor {
            top: 50px;

            .blackDoor {
                width: 430px;
            }
        }

        .hero__video {
            max-height: 650px;
            width: 1100px;
            left: 190px;
            top: 100px;
        }

        .banner__profile {
            &--first {
                bottom: 200px;
            }

            &--second {
                left: 90px;
            }

            .motto,
            .motto2 {
                font-family: "Oswald Bold";
                font-size: 72px;
                line-height: 90px;
            }
        }
    }
}
