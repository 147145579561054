$black: #111;
$white: #fff;
$milk : #F8EDED;


$xs: 360px;

$sm: 576px;
$sm-max: 575.98px;

$md: 768px;
$md-max: 767.98px;

$lg: 1024px;
$lg-max: 1023.98px;
$lg-plus: 1280px;

$xl: 1440px;
$xl-max: 1439.98px;

$xxl: 1600px;
$xxl-max: 1599.98px;
$xxl-plus: 1728px;

$grid-breakpoints: (
    xs: 0,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl,
);
