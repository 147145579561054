@import "../variables";

.slide-show {
    .custom-pagination {
        text-align: center;
        margin-top: 20px;

        .swiper-pagination-bullet-active {
            background-color: #fff;
        }
    }

    .subtitle {
        font-size: 30px;
        font-family: "Poppins Medium";
        text-decoration: underline;
        font-weight: 400;
        text-shadow: 0px 0px 14px rgba($white, 0.5);
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
    }

    .contents {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .content-item__image {
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;

            .image {
                width: 100%;
                // max-width: 400px;
                height: auto;
                border-radius: 10px;
                object-fit: cover;
                display: block;
            }
        }

        .content-item__video {
            height: 300px;
            width: 100%;

            .video {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (min-width: $md-max) {
    .slide-show {
        .subtitle {
            font-size: 42px;
            margin-left: unset;
        }

        .contents {
            .content-item__image {
                .image {
                    max-width: unset;
                }
            }

            .content-item__video {
                height: 400px;
                width: 100%;

                .video {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media (min-width: 1359.98px) {
    .slide-show {
        .subtitle {
            font-size: 64px;
            margin-right: 200px;
        }

        .contents {
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            // justify-content: center;
            align-items: center;

            .content-item__image {
                width: 100%;
                // max-width: 500px; aaa
                height: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .content-item__video {
                width: 100%;
                max-width: 100%;
                aspect-ratio: 1 / 1;

                .video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content-item__image:nth-child(1) {
                // grid-column: span 2;
                // grid-row: span 1;
            }

            .content-item__image:nth-child(2) {
                // grid-column: span 2;
                // grid-row: span 1;
            }

            .content-item__image:nth-child(3) {
                // grid-column: span 1;
                // grid-row: span 1;
            }

            .content-item__video:nth-child(4) {
                // grid-column: span 1;
                // grid-row: span 2;
            }

            .content-item__image:nth-child(5) {
                // grid-column: span 2;
                // grid-row: span 1;
            }

            .content-item__image:nth-child(6) {
                // grid-column: span 4;
                // grid-row: span 1;
            }
        }
    }
}
