@font-face {
    font-family: 'OpenSans Light';
    src: url('../fonts/OpenSans-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans Regular';
    src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans Medium';
    src: url('../fonts/OpenSans-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans SemiBold';
    src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans Bold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans ExtraBold';
    src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Light';
    src: url('../fonts/Oswald-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Regular';
    src: url('../fonts/Oswald-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Medium';
    src: url('../fonts/Oswald-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Oswald SemiBold';
    src: url('../fonts/Oswald-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Bold';
    src: url('../fonts/Oswald-Bold.ttf') format('truetype');
    font-display: swap;
}