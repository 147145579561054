@import "../variables";

.hero__name {
    margin: 20px 0px;

    .fullname {
        font-family: "Oswald Bold";
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;
        color: transparent;
        -webkit-text-stroke: 1px rgba($milk, 0.5);
        margin: 0;
    }
}

@media (min-width: 424.98px) {
    .hero__name {
        .fullname {
            font-size: 38px;
            letter-spacing: 2px;
        }
    }
}

@media (min-width: $md-max) {
    .hero__name {
        margin: 0px 0px 30px;

        .fullname {
            font-size: 78px;
            letter-spacing: 0px;
        }
    }
}

@media (min-width: $lg-max) {
    .hero__name {
        .fullname {
            font-size: 104px;
        }
    }
}

@media (min-width: 1359.98px) {
    .hero__name {
        .fullname {
            font-size: 138px;
        }
    }
}

@media (min-width: $xl-max) {
    .hero__name {
        .fullname {
            font-size: 144px;
        }
    }
}