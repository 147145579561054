@import "../variables";

.loader {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid $white;
    animation:
        l20-1 0.8s infinite linear alternate,
        l20-2 1.6s infinite linear;
}
@keyframes l20-1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}
@keyframes l20-2 {
    0% {
        transform: scaleY(1) rotate(0deg);
    }
    49.99% {
        transform: scaleY(1) rotate(135deg);
    }
    50% {
        transform: scaleY(-1) rotate(0deg);
    }
    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}

.works {
    padding: 24px 16px;

    .title {
        margin-top: 0px;
        font-size: 52px;
        text-transform: uppercase;
        font-family: "Oswald Bold";
        text-shadow: 0px 0px 14px rgba($white, 0.3);
        // margin-left: 24px;
        text-align: center;
        margin-bottom: 64px;
    }

    .gallery {
        padding: 0 24px 24px 24px;
    }
}

@media (min-width: $md-max) {
    .works {
        padding: 24px;

        .title {
            font-size: 64px;
            margin-left: 20px;
        }
    }
}

@media (min-width: 1359.98px) {
    .works {
        .title {
            font-size: 100px;
            // margin-left: 220px;
        }
    }
}
