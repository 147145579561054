@import "variables";
@import "./fonts";


* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  // background: linear-gradient(180deg, #202020 0%, #343a40 100%);
  background: #202020;
  // background: #343a40;
  // border: 2px solid blue;
  color: $white;
}

a {
  text-decoration: none;
}
